<template>
	<div class="abab">
		<div>
			<input class="absd" style="display: none;" />
		</div>
		<div class="imgList">
			<!-- <a :href="kefu_link">
				<img src="../assets/kefu.png" alt="">
			</a> -->
			<h2>{{login_1}}</h2>
			<a href="/index/user/lang.html">
				<img src="../assets/lang.png" alt="">
			</a>
		</div>
		<div class="logo">
			<img src="../assets/logo.png" alt="">
			<h2>TikTok-Wholesale</h2>
		</div>

		<div class="inputs">
			<div class="phone">
				<div class="name">{{ please_enter }}</div>
				<input type="text" name="user_name" @input="inname" />
			</div>
			<div class="password">
				<div class="name">{{ your_password }}</div>
				<input type="password" name="pwd" @input="inpass" />
			</div>
			<div class="loginBtn" @click="login">{{ login_1 }}</div>
			<div class="tips">
				<div>
					<i>
						<a style="color: #1d91ff;" :href="kefu_link">{{ kf_title }}</a>
					</i>
				</div>
				<div>
					<i>
						<a style="color: #1d91ff;" @click="register">{{ reg_title }}</a>
					</i>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: "Login",
	props: {
		msg: String,
	},
	data() {
		return {
			user: {
				user_name: "",
				pwd: "",
			},
			reme: false,

			please_enter: "",
			your_password: "",
			login_1: "",
			kf_title: "",
			no_account: "",
			reg_title: "",
			kefu_link: "",
		};
	},
	created() {
		// 初始化操作，如获取数据
		this.fetchData();
	},
	methods: {
		async fetchData() {
			var result = await this.$proxy.getvuelang();
			// console.log(result);
			var info = result.info;
			this.please_enter = info.please_enter;
			this.your_password = info.your_password;
			this.login_1 = info.login_1;
			this.no_account = info.no_account;
			this.kf_title = info.kf_title;
			this.reg_title = info.reg_title;
			this.kefu_link = info.kefu_link;

			if (typeof this.please_enter == "undefined") {
				this.please_enter = 'Please enter the platform account';
				this.your_password = 'your password';
				this.login_1 = 'login';
				this.no_account = 'No account?';
				this.reg_title = 'Register';
				this.kf_title = 'Customer service'
			}
		},
		// 获取用户名和密码
		inname(e) {
			this.user.user_name = e.target.value;
		},
		inpass(e) {
			this.user.pwd = e.target.value;
		},
		// 跳转到注册
		register() {
			this.$router.push({
				path: "/register"
			});
		},
		// 跳转到语言
		lang() {
			window.open('/index/user/lang', '_self');
		},
		// 登录
		async login() {
			if (this.user.user_name === "" || this.user.pwd === "") {
				this.$vs.notification({
					position: "top-center",
					icon: "<span class='material-icons' style='font-size: 24px;'>error</span>",
					color: "warn",
					title: '<span style="font-size: 18px;">Error</span>',
					text: '<span style="font-size: 14px;">Please enter account / password</span>',
				});
				return false;
			}
			// 登录接口
			var result = await this.$proxy.login(this.user);
			// console.log(result)
			if (result.code === 0) {
				//存入token
				// localStorage.setItem('token',result.data['token']);
				// alert(localStorage.token);
				const loading = this.$vs.loading({
					type: "circles",
					color: "#d5397b",
					text: "Login success",
				});
				this.hasOpenLoading = true;
				setTimeout(() => {
					loading.close();
					this.hasOpenLoading = false;
				}, 2000);
				sessionStorage.setItem('login_flag', '1');
				window.open('/index/index/home', '_self');
				// this.$router.replace("/index/index/home");
			} else {
				this.$vs.notification({
					position: "top-center",
					icon: "<span class='material-icons' style='font-size: 24px;'>error</span>",
					color: "warn",
					title: '<span style="font-size: 18px;">Error</span>',
					text: '<span style="font-size: 14px;">' + result.info + '</span>',
				});
			}
		},
	},
};
</script>

<style scoped>
body {
	font-size: 1rem;
	color: #333;
}

.abab {
	background: url(../assets/background.png) no-repeat;
	background-size: 100% 100%;
	width: 100%;
	min-height: 100vh;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	padding: 0 15px 20px;
	padding-top: calc(8vh + 60px);
	box-sizing: border-box;
	background-color: #1e1e1e;
	color: #fff;
}

.imgList {
	width: 100%;
    height: 60px;
    padding: 0 15px;
    position: fixed;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.imgList img {
	width: 25px;
	height: 25px;
}

.imgList h2{
	font-size: 24px;
    font-weight: 700;
}


.logo {
	display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 8vh;
}

.logo img{
	width: 20%;
}

.logo h2{
	font-size: 24px;
    font-weight: 700;
    margin-top: 8px;
}

.inputs {
	border-radius: 10px;
	padding: 0vh 10vw;
	box-sizing: border-box;
	margin: 0 auto 0 auto;
	width: 75vw;
	padding: 20px;
}

.name {
	font-size: 14px;
	font-weight: 700;
}

input {
	background: none;
	border: none;
	width: 100%;
	border-bottom: 1px solid #ccc;
	padding: 7px 0;
	font-size: 16px;
}

.password {
	margin-top: 20px;
}

.loginBtn {
	font-weight: 700;
	margin: 3vh auto 0 auto;
	height: 6vh;
	line-height: 6vh;
	text-align: center;
	border-radius: 5px;
	font-size: 20px;
	background: #000000;
}

.tips {
	width: 100%;
	text-align: center;
	font-size: 14px;
	color: #333;
	margin-top: 20px;
	display: flex;
}

.tips div {
	width: 50%;
	padding: 0 10px;
}
</style>
